import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { Grid, Typography } from '@mui/material';
import React from 'react';

export default function EnergyConsumeValue(props: any) {
    return (
        <React.Fragment>
            <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid xs={1}></Grid>
                <Grid xs={4}>
                    <LocalFireDepartmentIcon htmlColor='#ff3300' fontSize='large'/>
                </Grid>
                <Grid xs={6}>
                    <Typography variant="h6" sx={{ fontWeight: '550' }}>{props.value} 卡</Typography>
                    <Typography variant="h6" sx={{ fontWeight: '550' ,color: 'grey',fontSize:'1em' }}>能耗</Typography>
                </Grid>
                <Grid xs={1}></Grid>
            </Grid>
        </React.Fragment>
    );
}