import uniappBridge from '../Vendor/hejiaqinSdk.2.0.0.js';


export async function getCurrentLocationWithMobile(callback:any) {
    uniappBridge.init({uniapp:'force'})
    uniappBridge.currentLocation().then((res: any) => {
        let geo = gaodeToBaidu(res.Longitude,res.Latitude);
        callback(geo[0],geo[1]);
    });
}



export const gaodeToBaidu = (lng:any, lat:any) => {

  const x_PI = (Math.PI * 3000.0) / 180.0

  let z = Math.sqrt(lng * lng + lat * lat) + 0.00002 * Math.sin(lat * x_PI)

  let theta = Math.atan2(lat, lng) + 0.000003 * Math.cos(lng * x_PI)

  let bd_lng = z * Math.cos(theta) + 0.0065

  let bd_lat = z * Math.sin(theta) + 0.006

  return [bd_lng, bd_lat]

}
