import axios from 'axios';
import { UrlConstant } from '../Constant/UrlConstant';


export async function getWithAxios(url:any,callback:any){
    try{
        const response = await axios.get(url);
        callback(response.data);
        return response.data;
    }
    catch(err){
        console.error(err);
    }
}

export async function postWithAxios(url:any,data:any,callback:any){
    try{
        const response = await axios.post(url,data);
        callback(response.data);
        return response.data;
    }
    catch(err){
        console.error(err);
    }
}