import React from "react";

import ReactEcharts from 'echarts-for-react';
import { Grid } from "@mui/material";


export default function StepTotalPie(props: any) {
    const stepTotalPieOption = {
        title: {
            text: '',
            // subtext: 'Fake Data',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        color:['#FEDDC9','#E97002'],
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['40%','60%'],
                data: [
                    { value: 1048, name: '已完成步数' },
                    { value: 735, name: '未完成步数' },
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };




    return (
        <React.Fragment>
            <Grid sx={{height: '30vh', margin: '0px',  background: '#F8F8F8;' }}>
                <ReactEcharts option={stepTotalPieOption} style={{ height: '95%', borderRadius: '10%', background: '#F8F8F8' }} />
            </Grid>
        </React.Fragment>
    );
}