class PetMarker extends BMapGL.Marker {
  callback:any;
  deviceSn: string;
  constructor(point: BMapGL.Point,deviceSn: string ,callback:any, opts?: BMapGL.MarkerOptions ){
    super(point, opts);
    this.deviceSn = deviceSn;
    this.onclick = (event: { type: string, target: any }) => {

    };
    this.callback = callback;
  }

  setPosition(position: BMapGL.Point): void{
    super.setPosition(position);
  }
  getPosition(): BMapGL.Point{
    return  super.getPosition();
  }

}

export default PetMarker;