import LocateRoleControl from "../Control/LocateRoleControl";
import AlarmControl from "..//Control/AlarmControl";
import MultiActionControl from "..//Control/MultiActionControl";
import TraceControl from "..//Control/TraceControl";
import PetMarker from "../Marker/PetMarker";
import { UrlConstant } from "../Constant/UrlConstant";
import DefaultScaleControl from "../Control/DefaultScaleControl";
import { ScaleControl } from "react-bmapgl";
import NavigateToDestinationControl from "../Control/NavigateToDestinationControl";
import PetTraceWithHistoryControl from "../Control/PetTraceWithHistoryControl";

export default function appMapInit(mqttClient: any, appContext: any) {
    appContext.map = new BMapGL.Map(
        appContext.mapRef.current,
        {
            maxZoom: 54,
            enableHighResolution: false,
            enableAutoResize: false,
            enableMapClick: false
        }
    );

    // const petInfoWindowDiv = document.createElement('div');
    // petInfoWindowDiv.style.width = '200px';
    // petInfoWindowDiv.style.height = '100px';
    // petInfoWindowDiv.innerHTML = '宠物信息';
    // const petInfoWindow = new BMapGL.InfoWindow(
    //     petInfoWindowDiv,
    //     {
    //         offset: new BMapGL.Size(0, -20),
    //         title: '宠物信息'
    //     }
    // );


    // appContext.map.addOverlay(petInfoWindow);


    appInitAlarmControl(appContext);
    appInitMultiActionControl(appContext);
    // appInitMapTraceControl(appContext);
    appInitMapNavigationControl(appContext);

    appInitMapTypeControl(appContext);
    appInitMapZoomControl(appContext);
    appInitMapPetMarker(appContext)
    appInitMapMyMarker(appContext, appContext.state.myPointer);
    appInitMapPanoramaControl(appContext)
    let scaleControl = appInitMapScaleControl(appContext);
    appInitMapDefaultScaleControl(appContext,scaleControl);
    appInitMapLocateRoleControl(appContext);
    appInitMapNavigateToDestinationControl(appContext);

    appContext.map.centerAndZoom(appContext.state.myPointer, 21);
    appContext.map.enableScrollWheelZoom(true);
}

function appInitAlarmControl(appContext: any){
    const alarmControl = new AlarmControl(() => {
        appContext.petMqtt?.mqttClient?.publish('/' + UrlConstant.DEVICE_SN + '/alarm', JSON.stringify({ "tagbuzset": "03" }));
        appContext.setState({ isAlarm: true });
        console.log(appContext.state.isAlarm)
        setInterval(() => {
            appContext.setState({ isAlarm: false });
        }, 3000);
    });
    appContext.map.addControl(alarmControl);
}


function appInitMultiActionControl(appContext:any){
    const multiActionControl = new MultiActionControl(() => {

    });
    appContext.map.addControl(multiActionControl);

}

function appInitMapTraceControl(appContext:any){
    const traceControl = new TraceControl(() => {

    });
    appContext.map.addControl(traceControl);
}

function appInitMapNavigationControl(appContext:any){
    const navigationControl = new BMapGL.NavigationControl();

}



function appInitMapTypeControl(appContext:any){
    const mapTypeControl = new BMapGL.MapTypeControl(
        {
            mapTypes: [
                BMAP_NORMAL_MAP,
                BMAP_HYBRID_MAP
            ]
        }
    );
    mapTypeControl.setAnchor(BMAP_ANCHOR_BOTTOM_RIGHT);
    mapTypeControl.setOffset(new BMapGL.Size(14, 31));
    appContext.map.addControl(mapTypeControl);
}


function appInitMapZoomControl(appContext:any){
    const zoomControl = new BMapGL.ZoomControl();
    appContext.map.addControl(zoomControl);
}


function appInitMapPetMarker(appContext:any){
    const petPointer = new BMapGL.Point(113.96511, 22.548982);
    appContext.petIcon = new BMapGL.Icon(UrlConstant.BASE_URL+"/pet.png", new BMapGL.Size(50, 50));
    appContext.petMarker = new PetMarker(
        petPointer,
        UrlConstant.DEVICE_SN,
        (deviceSn: string) => {

        },
        {
            icon: appContext.petIcon,
            enableClicking: true,
        }
    );
    appContext.map.addOverlay(appContext.petMarker);
}

function appInitMapMyMarker(appContext:any,pointer:any){
    appContext.myIcon = new BMapGL.Icon(UrlConstant.BASE_URL+"/person.png", new BMapGL.Size(50, 50));
    appContext.myMarker = new BMapGL.Marker(
        pointer,
        {
            icon: appContext.myIcon
        }
    );
    appContext.map.addOverlay(appContext.myMarker);
}

function appInitMapPanoramaControl(appContext:any){
    const panoramaControl = new BMapGL.PanoramaControl();
    panoramaControl.setOffset(new BMapGL.Size(14, 139));
    panoramaControl.setAnchor(BMAP_ANCHOR_BOTTOM_RIGHT);
    appContext.map.addControl(panoramaControl);
}

// function appInitMapScaleControl(appContext:any){
//     const scaleControl = new BMapGL.ScaleControl();
//     appContext.map.addControl(scaleControl);
// }


function appInitMapLocateRoleControl(appContext:any){
    const locateRoleControl = new LocateRoleControl(() => {
        if (appContext.state.isPetPointerCenter == true) {
            appContext.map.setCenter(appContext.state.petPointer);
            appContext.setState({isPetPointerCenter: false});
        }
        else if(appContext.state.isPetPointerCenter == false){
            appContext.map.setCenter(appContext.state.myPointer);
            appContext.setState({isPetPointerCenter: true});
        }
    });

    appContext.map.addControl(locateRoleControl);
}

function appInitMapDefaultScaleControl(appContext:any,scaleControl:any){
    const defaultScaleControl = new DefaultScaleControl(() => {
        if(appContext.state.isScaleControlShow == false){
            appContext.setState({isScaleControlShow: true});
            scaleControl.show();
        }
        else if(appContext.state.isScaleControlShow == true){
            appContext.setState({isScaleControlShow: false});
            scaleControl.hide();
        }
    });
    appContext.map.addControl(defaultScaleControl);
}

function appInitMapScaleControl(appContext:any){
    const scaleControl = new BMapGL.ScaleControl();
    scaleControl.hide();
    appContext.map.addControl(scaleControl);
    return scaleControl;
}

function appInitMapNavigateToDestinationControl(appContext:any){
    appContext.driveRoute = new BMapGL.WalkingRoute(appContext.map, { renderOptions: { map: appContext.map, autoViewport: true } }); 
    const navigateToDestinationControl = new NavigateToDestinationControl(() => {
        if (appContext.state.isDriveRouteShow == false) {
            appContext.driveRoute.search(appContext.state.myPointer, appContext.state.petPointer);
            appContext.setState({ isDriveRouteShow: true });
        }
        else if (appContext.state.isDriveRouteShow == true) {
            appContext.driveRoute.clearResults();
            appContext.setState({ isDriveRouteShow: false });
        }
    });

    appContext.map.addControl(navigateToDestinationControl);
}



export function traceMapInit(mqttClient: any, appContext: any) {
    appContext.map = new BMapGL.Map(
        appContext.mapRef.current,
        {
            maxZoom: 54,
            enableHighResolution: false,
            enableAutoResize: false,
            enableMapClick: false
        }
    );

    traceInitMapPetTraceWithHistoryControl(appContext);

    traceInitMapZoomControl(appContext);
    traceInitMapPetMarker(appContext)
    traceInitMapMyMarker(appContext, appContext.state.myPointer);
    let scaleControl = traceInitMapScaleControl(appContext);
    traceInitMapDefaultScaleControl(appContext,scaleControl);
    traceInitMapLocateRoleControl(appContext);

    appContext.map.centerAndZoom(appContext.state.myPointer, 21);
    appContext.map.enableScrollWheelZoom(true);
}

function traceInitMapPetTraceWithHistoryControl(appContext:any){
    const petTraceWithHistoryControl = new PetTraceWithHistoryControl(() => {
        if(appContext.state.isHistoryTracePointShow == false){
            appContext.state.historyTracePointMarker.forEach((item:any) => {
                appContext.map.addOverlay(item);
            });

            appContext.setState({isHistoryTracePointShow: true})
        }
        else if(appContext.state.isHistoryTracePointShow == true){
            appContext.state.historyTracePointMarker.forEach((item:any) => {
                appContext.map.removeOverlay(item);
            });
            appContext.setState({isHistoryTracePointShow: false})
        }
    });

    appContext.map.addControl(petTraceWithHistoryControl);
}

function traceInitMapZoomControl(appContext:any){
    const zoomControl = new BMapGL.ZoomControl();
    appContext.map.addControl(zoomControl);
}


function traceInitMapPetMarker(appContext:any){
    const petPointer = new BMapGL.Point(113.96511, 22.548982);
    appContext.petIcon = new BMapGL.Icon(UrlConstant.BASE_URL+"/pet.png", new BMapGL.Size(50, 50));
    appContext.petMarker = new PetMarker(
        petPointer,
        UrlConstant.DEVICE_SN,
        (deviceSn: string) => {

        },
        {
            icon: appContext.petIcon,
            enableClicking: true,
        }
    );
    appContext.map.addOverlay(appContext.petMarker);
}

function traceInitMapMyMarker(appContext:any,pointer:any){
    appContext.myIcon = new BMapGL.Icon(UrlConstant.BASE_URL+"/person.png", new BMapGL.Size(50, 50));
    appContext.myMarker = new BMapGL.Marker(
        pointer,
        {
            icon: appContext.myIcon
        }
    );
    appContext.map.addOverlay(appContext.myMarker);
}

function traceInitMapLocateRoleControl(appContext:any){
    const locateRoleControl = new LocateRoleControl(() => {
        if (appContext.state.isPetPointerCenter == true) {
            appContext.map.setCenter(appContext.state.petPointer);
            appContext.setState({isPetPointerCenter: false});
        }
        else if(appContext.state.isPetPointerCenter == false){
            appContext.map.setCenter(appContext.state.myPointer);
            appContext.setState({isPetPointerCenter: true});
        }
    });

    appContext.map.addControl(locateRoleControl);
}

function traceInitMapDefaultScaleControl(appContext:any,scaleControl:any){
    const defaultScaleControl = new DefaultScaleControl(() => {
        if(appContext.state.isScaleControlShow == false){
            appContext.setState({isScaleControlShow: true});
            scaleControl.show();
        }
        else if(appContext.state.isScaleControlShow == true){
            appContext.setState({isScaleControlShow: false});
            scaleControl.hide();
        }
    });
    appContext.map.addControl(defaultScaleControl);
}

function traceInitMapScaleControl(appContext:any){
    const scaleControl = new BMapGL.ScaleControl();
    scaleControl.hide();
    appContext.map.addControl(scaleControl);
    return scaleControl;
}
