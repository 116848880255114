import mqtt from 'mqtt';
import { UrlConstant } from '../Constant/UrlConstant';


export default class PetMqtt {
    mqttClient: any;
    mqttInit(callback: any): any {
        this.mqttClient = mqtt.connect(
            UrlConstant.WSS_URL,
            {
                username: UrlConstant.MQTT_USERNAME,
                password: UrlConstant.MQTT_PASSWORD,
            }
        );

        this.mqttClient.on('reconnect', (error:any) => {
            console.log('正在重连:', error)
        })

        this.mqttClient.on('error', (error:any) => {
            console.log('连接失败:', error)
        })

        this.mqttClient.on('connect', () =>{
            // /{deviceSn}/location
            this.mqttClient.subscribe('/' + UrlConstant.DEVICE_SN +  '/location/pet', function (err: any) {
                if (!err) {
                }
            });
            this.mqttClient.subscribe('/' + UrlConstant.DEVICE_SN +  '/location/mime', function (err: any) {
                if (!err) {
                }
            });

            setInterval(() => {
                this.mqttClient.sendPing();
            }, 30000);

        })

        this.mqttClient.on('message', (topic: any, message: any) => {
            callback(topic, message.toString());
        })
    }
}

