import React from "react";
import ReactEcharts from 'echarts-for-react';
import { Grid } from "@mui/material";

export default function StepDistributionHistogram(props:any) {
    const stepDistributionOption = {
        title: {
            text: '24小时内步数分布',
            textStyle: {
                color: 'grey'
            },
            left: 'center'
        },
        color:['#FEDDC9','#E97002'],
        xAxis: {
            type: 'category',
            data: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: props.statisticContext.state.stepDistributtionData,
                type: 'bar'
            }
        ]
    };
    return (
        <React.Fragment>
            <Grid sx={{height: '32vh', margin: '0px',  background: '#F8F8F8;' }}>
                <ReactEcharts option={stepDistributionOption} style={{ height:'95%',  background: 'white', borderRadius: '10%' }} />
            </Grid>
        </React.Fragment>
    );
}