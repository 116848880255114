import { Divider, Grid, Typography } from "@mui/material";
import {Map, MapApiLoaderHOC, Marker, ZoomControl }from 'react-bmapgl';
import React from "react";
import { Footer } from "../../Slot/Footer";
import PetMarker from "../../Marker/PetMarker";
import {traceMapInit} from '../../Context/AppContext';
import mqttInit from '../../Utils/petMqtt';
import PetMqtt from "../../Utils/petMqtt";
import { getWithAxios } from "../../Utils/axiosUtil";
import { UrlConstant } from "../../Constant/UrlConstant";
import PetMarkerWithHistory from "../../Marker/PetMarkerWithHistory";
import { addLocationFlow, getLocationFlowList } from "../../Persistent/locationFlow";
import moment from "moment";
import './Trace.css';
import { getCurrentLocationWithMobile } from "../../Utils/geoUtil";

 class Trace extends React.Component {
    mqttClient: any
    map: any;
    mapRef: any;
    petIcon: BMapGL.Icon | undefined;
    petTraceWithHistoryIcon: BMapGL.Icon | undefined;
    myIcon: BMapGL.Icon | undefined;
    petMarker: PetMarker | undefined;
    myMarker: BMapGL.Marker | undefined
    petMqtt: mqttInit | undefined;
    driveRoute: any;
    state = {
        isAlarm: false,
        currentPage: 'default',
        petPointer: new BMapGL.Point(113.96511, 22.548982),
        myPointer: new BMapGL.Point(113.965133, 22.549241),
        isPetPointerCenter: false,
        isScaleControlShow: false,
        isDriveRouteShow: false,
        isHistoryTracePointShow: false,
        historyTracePointMarker: []
    }

    constructor(props: any) {
        super(props);
        this.mapRef = React.createRef();
    }

    componentDidMount() {
        this.petTraceWithHistoryIcon = new BMapGL.Icon(UrlConstant.BASE_URL + "/petActiveColor.png", new BMapGL.Size(23, 25), {
            anchor: new BMapGL.Size(10, 25),
            imageSize: new BMapGL.Size(23, 25)
        });
        this.petMqtt = new PetMqtt();
        this.petMqtt.mqttInit((topic: any, message: any) => {
            if (topic == '/' + UrlConstant.DEVICE_SN + '/location/pet') {
                const tmpJson: any = JSON.parse(message.toString());
                this.petMarker?.setPosition(new BMapGL.Point(tmpJson.lng, tmpJson.lat));
                this.setState({ petPointer: new BMapGL.Point(tmpJson.lng, tmpJson.lat) });
                addLocationFlow({ lng: tmpJson.lng, lat: tmpJson.lat, ts: moment().seconds(), deviceSn: UrlConstant.DEVICE_SN });
            }
            else if (topic == '/' + UrlConstant.DEVICE_SN +'/location/mime') {
                const tmpJson = JSON.parse(message.toString());
                this.myMarker?.setPosition(new BMapGL.Point(tmpJson.lng, tmpJson.lat));
                this.setState({ myPointer: new BMapGL.Point(tmpJson.lng, tmpJson.lat) });
            }
        });

        setInterval(() => {
            getCurrentLocationWithMobile((lng: any, lat: any) => {
                this.myMarker?.setPosition(new BMapGL.Point(lng, lat));
                this.setState({ myPointer: new BMapGL.Point(lng, lat) });
            });
        }, 3000);

        traceMapInit(this.mqttClient, this);
        getLocationFlowList(this);
        // getWithAxios(UrlConstant.API_URL + "/locationFlow", (data: any) => {
        //     this.setState({
        //         historyTracePointMarker: data.data?.map((item: any) => {
        //             let point = new BMapGL.Point(item.lng, item.lat);
        //             let marker = new BMapGL.Marker(point, { icon: this.petTraceWithHistoryIcon });
        //             return marker;
        //         })
        //     });
        // });
    }

    render() {
        return (
            <React.Fragment>
                <Grid container justifyContent={'center'} alignItems={'center'} sx={{ height: '6vh', background: '#F36A21'  }}>
                    <Typography variant="h6" sx={{ }} color={'white'}>轨迹</Typography>
                </Grid>
                <div ref={this.mapRef} style={{ height: "88vh" }} >
                </div>
                <Footer appContext={this} active="2" />
            </React.Fragment>
        );

    }
}

export default MapApiLoaderHOC({ ak: 'hb94tpvILyufTNU9HAe4HXo0BhTIrgAm' })(Trace);

// #F36A21