import { Grid, Typography } from "@mui/material";
import React from "react";
import DarkModeIcon from '@mui/icons-material/DarkMode';

export default function SleepTimeValue(props: any) {
    return (
        <React.Fragment>
            <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid xs={1}></Grid>
                <Grid xs={4}>
                    <DarkModeIcon htmlColor="#065279" fontSize="large"/>
                </Grid>
                <Grid xs={6}>
                    <Typography variant="h6" sx={{ fontWeight: '550' }}>{props.value} 时</Typography>
                    <Typography variant="h6" sx={{ fontWeight: '550', color: 'grey',fontSize:'1em' }}>睡眠时间</Typography>
                </Grid>
                <Grid xs={1}></Grid>
            </Grid>
        </React.Fragment>
    );
}