import { UrlConstant } from "../Constant/UrlConstant";

class MultiActionControl extends BMapGL.Control{
  callback: any;
  constructor(callback:any){
    super();
    this.callback = callback;
  }

  initialize(map: BMapGL.Map): HTMLElement {
    const div = document.createElement('div');
     div.style.right = '10px';
    div.style.top = (10+56*2)+'px' ;
    div.style.width = '49px';
    div.style.height= '46px';
    div.style.padding = '0px';
    div.style.margin = '0px';
    div.style.display= 'flex';
    div.style.alignItems = 'center';
    div.style.justifyContent = 'center';
    div.style.backgroundColor = '#96d36b';
    div.addEventListener('touchstart', () => {
      this.callback();
    });
    div.innerHTML = '<img src="'+UrlConstant.BASE_URL+'/multiple.png" style="width: 30px; height: 30px;"/>';
    map.getContainer().appendChild(div);
    return div;
  }
  defaultAnchor: BMapGL.ControlAnchor = BMAP_ANCHOR_BOTTOM_LEFT;
  defaultOffset: BMapGL.Size = new BMapGL.Size(80, 80);
  setAnchor(anchor: BMapGL.ControlAnchor): void {

  }
  getAnchor(): BMapGL.ControlAnchor {
    return this.defaultAnchor;
  }
  setOffset(offset: BMapGL.Size): void {
  }
  getOffset(): BMapGL.Size {
    return this.defaultOffset;
  }
  show(): void {

  }
  hide(): void {

  }
  isVisible(): boolean {
    return true;
  }
}


export default MultiActionControl;