import { Grid, Typography } from "@mui/material";
import React from "react";
import PetsIcon from '@mui/icons-material/Pets';

export default function DistanceValue(props: any) {
    return (
        <React.Fragment>
            <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid xs={1}></Grid>
                <Grid xs={4}>
                    <PetsIcon htmlColor="#3d3b4f" fontSize="large"/>
                </Grid>
                <Grid xs={6}>
                    <Typography variant="h6" sx={{ fontWeight: '550' }}>{props.value} 里</Typography>
                    <Typography variant="h6" sx={{ fontWeight: '550' ,color: 'grey',fontSize:'1em' }}>运动距离</Typography>
                </Grid>
                <Grid xs={1}></Grid>
            </Grid>
        </React.Fragment>
    );
}