
import React from 'react';
import {Map, MapApiLoaderHOC, Marker, ZoomControl }from 'react-bmapgl';
import './App.css';
import mqttInit from '../../Utils/petMqtt';
import PetMqtt from '../../Utils/petMqtt';
import appMapInit from '../../Context/AppContext';
import PetMarker from '../../Marker/PetMarker';
import './App.css';
import { Footer } from '../../Slot/Footer';
import { getCurrentLocationWithMobile } from '../../Utils/geoUtil';
import ts from 'typescript';
import { UrlConstant } from '../../Constant/UrlConstant';
import { addLocationFlow } from '../../Persistent/locationFlow';
import moment from 'moment';

class App extends React.Component {
  mqttClient: any
  map: any;
  mapRef: any;
  petIcon: BMapGL.Icon | undefined;
  myIcon: BMapGL.Icon | undefined;
  petMarker: PetMarker | undefined; 
  myMarker: BMapGL.Marker  | undefined
  petMqtt: mqttInit | undefined;
  driveRoute: any;
  state = {
    isAlarm: false,
    isPetMarkerDialog: false,
    currentPage:'default',
    petPointer: new BMapGL.Point(113.96511, 22.548982), 
    myPointer: new BMapGL.Point(113.965133, 22.549241),
    isPetPointerCenter: false,
    isScaleControlShow: false,
    isDriveRouteShow: false
  }

  constructor(props: any) {
    super(props);
    this.mapRef = React.createRef();

  }



  componentDidMount() {
    this.petMqtt = new PetMqtt();
    this.petMqtt.mqttInit((topic: any, message: any) => {
      if (topic == '/' + UrlConstant.DEVICE_SN + '/location/pet') {
        const tmpJson:any = JSON.parse(message.toString());
        this.petMarker?.setPosition(new BMapGL.Point(tmpJson.lng, tmpJson.lat));
        this.setState({petPointer: new BMapGL.Point(tmpJson.lng, tmpJson.lat)});
        addLocationFlow({ lng: tmpJson.lng, lat: tmpJson.lat, ts: moment().seconds(), deviceSn: UrlConstant.DEVICE_SN });

      }
      else if (topic == '/' + UrlConstant.DEVICE_SN + '/location/mime') {
        const tmpJson = JSON.parse(message.toString());
        this.myMarker?.setPosition(new BMapGL.Point(tmpJson.lng, tmpJson.lat));
        this.setState({myPointer: new BMapGL.Point(tmpJson.lng, tmpJson.lat)});
      }
    });
    setInterval(() => {
      getCurrentLocationWithMobile((lng: any, lat: any) => {
        this.myMarker?.setPosition(new BMapGL.Point(lng, lat));
        this.setState({myPointer: new BMapGL.Point(lng, lat)});
      });
    }, 3000);

    appMapInit(this.mqttClient, this);
  }
  render() {
    return (
      <React.Fragment>
        {/* <Map ref={this.mapRef} zoom={0} center={''} />  */}
        <div ref={this.mapRef} style={{ height: "94vh" }} >
        </div>
        <Footer appContext={this} active="0" ></Footer>
      </React.Fragment>
    )
  }
}

export default MapApiLoaderHOC({ ak: 'hb94tpvILyufTNU9HAe4HXo0BhTIrgAm' })(App);
