import React from "react";
import { Alert, BottomNavigation, BottomNavigationAction, Divider, Grid, Typography } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { Navigate } from "react-router-dom";
import AssessmentIcon from '@mui/icons-material/Assessment';
import PetsIcon from '@mui/icons-material/Pets';
import { getActiveElement } from "@testing-library/user-event/dist/utils";

export function Footer(props:any) {
    return (
        <React.Fragment>
            {
                props.appContext.state.isAlarm == false &&
                <BottomNavigation
                sx={{height: '6vh'}}
                    showLabels
                >
                        {<BottomNavigationAction label="地图" icon={<LocationOnIcon />} sx={{ margin: 0, padding: 0, color: props.active == 0? '#F36A21':'' }} onClick={() =>{
                            props.appContext.setState({ currentPage: '/' });
                        } } />}
                        {<BottomNavigationAction label="统计" icon={<AssessmentIcon />} sx={{ margin: 0, padding: 0, color: props.active == 1? '#F36A21':''  }} onClick={() =>{
                            props.appContext.setState({ currentPage: '/Statistic' });
                        } } />}
                        {<BottomNavigationAction label="轨迹" icon={<PetsIcon />} sx={{ margin: 0, padding: 0, color: props.active == 2? '#F36A21':''  }} onClick={() => {
                            props.appContext.setState({ currentPage: '/Trace' });
                        }} />}
                </BottomNavigation>
            }
            {props.appContext.state.isAlarm == true && <Alert severity="success">响铃已发送</Alert>}
            {props.appContext.state.currentPage == '/' && <Navigate to="/" />}
            {props.appContext.state.currentPage == '/Statistic' && <Navigate to="/Statistic" />}
            {props.appContext.state.currentPage == '/Trace' && <Navigate to="/Trace" />}
        </React.Fragment>
    );
}
