import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { Grid, Typography } from '@mui/material';
import React from 'react';

export default function ActiveTimeValue(props: any) {
    return (
        <React.Fragment>
            <Grid container justifyContent={'center'} alignItems={'center'} >
                <Grid xs={1}></Grid>
                <Grid xs={4} >
                    <MonitorHeartIcon  htmlColor='#F36A21' fontSize='large' />
                </Grid>
                <Grid xs={6}>
                    <Typography variant="h6" sx={{ fontWeight: '550' }}>{props.value} 时</Typography>
                    <Typography variant="h6" sx={{ fontWeight: '550', color: 'grey',fontSize:'1em' }}>活跃时间</Typography>
                </Grid>
                <Grid xs={1}></Grid>
            </Grid>
        </React.Fragment>
    );
}