import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router';
import { createHashRouter } from 'react-router-dom';
import App from './Pages/App/App';
import 'core-js/es'  
import 'react-app-polyfill/ie9'  
import 'react-app-polyfill/stable'
import Statistic from './Pages/Statistic/Statistic';
import Trace from './Pages/Trace/Trace';



const router = createHashRouter([
  {
    path: '/',
    element: <App />
  },
  {
    path: '/Statistic',
    element: <Statistic />
  },
  {
    path: '/Trace',
    element: <Trace />
  }

]);

ReactDOM.render(
    <RouterProvider router={router} />,
    document.getElementById('root')
);