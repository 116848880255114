import { UrlConstant } from "../Constant/UrlConstant";

class DefaultScaleControl extends BMapGL.ScaleControl{
  callback: any;

  constructor(callback: any){
    super();
    this.callback = callback;
  }

  initialize(map: BMapGL.Map): HTMLElement {
    const div = document.createElement('div');
    div.style.left = '15px';
    div.style.bottom = (55)+'px';
    div.style.width = '35px';
    div.style.height= '35px';
    div.style.padding = '0px';
    div.style.margin = '0px';
    div.style.display= 'flex';
    div.style.alignItems = 'center';
    div.style.justifyContent = 'center';
    div.style.backgroundColor = 'rgba(255, 255, 255, 1)';
    div.style.borderRadius = '50%';
    // div.style.opacity = '20%';
    div.addEventListener('touchstart', () => {
      this.callback();
      this.show();
    });
    div.innerHTML = '<img src="'+UrlConstant.BASE_URL+'/ruler.png" style="width: 23px; height: 23px;"/>';
    map.getContainer().appendChild(div);
    return div;
  }
  defaultAnchor: BMapGL.ControlAnchor = BMAP_ANCHOR_BOTTOM_LEFT;
  defaultOffset: BMapGL.Size = new BMapGL.Size(0, 0);
  setAnchor(anchor: BMapGL.ControlAnchor): void {
  }
  getAnchor(): BMapGL.ControlAnchor{
    return this.defaultAnchor;
  }
  setOffset(offset: BMapGL.Size): void{
    super.setOffset(offset);
  }
  getOffset(): BMapGL.Size{
    return this.defaultOffset;
  }
  show(): void{
    super.show();
  }
  hide(): void{
    super.hide();
  }
  isVisible(): boolean{
    return true;
  }
}


export default DefaultScaleControl;