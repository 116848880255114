import { UrlConstant } from "../Constant/UrlConstant";
import { getWithAxios, postWithAxios } from "../Utils/axiosUtil";

export function getLocationFlowList(traceContext:any){
        getWithAxios(UrlConstant.API_URL + "/locationFlow", (data: any) => {
            traceContext.setState({
                historyTracePointMarker: data.data?.map((item: any) => {
                    let point = new BMapGL.Point(item.lng, item.lat);
                    let marker = new BMapGL.Marker(point, { icon: traceContext.petTraceWithHistoryIcon });
                    return marker;
                })
            });
        });
}

export function addLocationFlow(point:any){
    postWithAxios(UrlConstant.API_URL + "/locationFlow",point,(data:any)=>{

    });

}
