import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Footer } from "../../Slot/Footer";
import { Height } from "@mui/icons-material";
import StepDistributionHistogram from "../../Statistic/StepDistributionHistogram";
import StepTotalPie from "../../Statistic/StepTotalPie";
import ActiveTimeValue from "../../Statistic/ActiveTimeValue";
import SleepTimeValue from "../../Statistic/SleepTimeValue";
import EnergyConsumeValue from "../../Statistic/EnergyConsumeValue";
import DistanceValue from "../../Statistic/DistanceValue";
import './Statistic.css';

export default class Statistic extends React.Component {
    state = {
        isAlarm: false,
        currentPage: 'default',
        stepDistributtionData: ['30', '20', '00', '00', '20', '30', '60', '90', '120', '250', '230', '350', '300', '450', '600', '500', '350', '400', '500', '350', '300', '280', '200', '150'],
        stepTotalData: [],
        activeTimeValue: 12.3,
        sleepTimeValue: 8.5,
        energyConsumeValue: 100,
        DistanceValue: 1000
    }


    render(){
        return (
            <React.Fragment>
                <Grid container justifyContent={'center'} alignItems={'center'} sx={{ height: '6vh', background: '#F36A21' }}>
                    <Typography variant="h6" sx={{ color:'white' }}>运动统计</Typography>
                </Grid>
                {/* <Divider /> */}
                <StepTotalPie statisticContext={this} />
                <StepDistributionHistogram  statisticContext={this} />
                <Grid container justifyContent={'center'} alignItems={'center'} direction={'row'} sx={{ height: '26vh', background: '#F8F8F8;' }}>
                    <Grid container justifyContent={'center'} alignItems={'center'} direction={'row'} sx={{height: '47%'}}>
                        <Grid  justifyContent={'center'} alignItems={'center'} container xs={5} sx={{background: 'white', height:'100%', borderRadius: '10%'}}>
                            <ActiveTimeValue value={this.state.activeTimeValue} />
                        </Grid>
                        <Grid xs={0.2}></Grid>
                        <Grid  justifyContent={'center'} alignItems={'center'} container xs={5} sx={{background: 'white', height:'100%',borderRadius: '10%'}}>
                            <SleepTimeValue value={this.state.sleepTimeValue} />
                        </Grid>
                    </Grid>
                    <Grid sx={{height:'6%'}}></Grid>
                    <Grid container justifyContent={'center'} alignItems={'center'} direction={'row'} sx={{height: '47%'}}>
                        <Grid  justifyContent={'center'} alignItems={'center'} container xs={5} sx={{background: 'white', height: '100%', borderRadius: '10%'}}>
                            <EnergyConsumeValue value={this.state.energyConsumeValue} />
                        </Grid>
                        <Grid xs={0.2}></Grid>
                        <Grid  justifyContent={'center'} alignItems={'center'} container xs={5} sx={{background: 'white', height: '100%', borderRadius: '10%'}}>
                            <DistanceValue value={this.state.DistanceValue} />
                        </Grid>
                    </Grid>
                </Grid>
                <Footer appContext={this} active="1" />
            </React.Fragment>

        )
    }
}

